<template>
    <div class="page-sip-trunking-tools-advanced">
        <div class="row">
            <div class="col-12">Advanced</div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
// .page-sip-trunking-tools-advanced {}

@media (max-width: $tablet-size) {
    // .page-sip-trunking-tools-advanced {}
}

@media (max-width: $mobile-size) {
    // .page-sip-trunking-tools-advanced {}
}
</style>